<template>
  <v-form ref="formElem">
    <translation-locales />

    <v-card>
      <v-card-text>
        <v-select
          v-model="form.team_id"
          label="Team"
          item-text="title"
          item-value="value"
          outlined
          :items="teamOptions"
          :disabled="!!resource"
        ></v-select>

        <v-text-field
          v-model="form.slug"
          label="Slug"
          hint="https://app.mixcarehealth.com/campaign/{slug}"
          outlined
          class="mt-5"
        />

        <v-text-field
          v-model="form.coupon_code"
          label="Coupon Code (optional)"
          hint="leave blank if no automatic code application"
          outlined
          class="mt-5"
        />

        <!-- TODO: the rules have an expected error -->
        <v-text-field
          v-model="form.credits_reference"
          label="Credits earned upon registration (display only)"
          hint="leave 0 if no credits upon registraion"
          outlined
          class="mt-5"
          :rules="between(form.credits_reference, 0, 9999999)"
        />
        <v-text-field
          v-model="form.cta_url"
          label="CTA target url"
          hint="This is the send-user-to page when he clicks CTA from a campaign. Will go to register and verification page first depending on requirements of the target page."
          placeholder="https://app.mixcarehealth.com/medical-pass/subscribe"
          outlined
          class="mt-5"
        />

        <translatable-input
          v-model="form.title"
          label="Title"
          outlined
          class="mt-5"
        />

        <translatable-editor
          v-model="form.reg_brand"
          label="Brand Section in Registration above Sign up"
          outlined
          class="mt-5"
          :eidtor-options="{ uploadUrl: 'campaign', postUuid: form.post_uuid }"
        />

        <translatable-editor
          v-model="form.reg_intro"
          label="Intro in Registration Under Sign up"
          outlined
          class="mt-5"
          :eidtor-options="{ uploadUrl: 'campaign', postUuid: form.post_uuid }"
        />

        <translatable-editor
          v-model="form.content"
          label="Content"
          outlined
          class="mt-5"
          :eidtor-options="{ uploadUrl: 'campaign', postUuid: form.post_uuid }"
        />

        <h3 class="mt-2">
          From
        </h3>

        <v-date-picker v-model="form.started_at" />

        <v-divider class="mt-2"></v-divider>

        <h3 class="mt-2">
          To
        </h3>

        <v-date-picker v-model="form.expires_at" />

        <v-switch
          v-model="form.active"
          color="primary"
          :label="form.active ? 'Active' : 'Inactive'"
        ></v-switch>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import { now } from '@/@core/utils/filter'
import { TranslatableEditor, TranslatableInput, TranslationLocales } from '@/components'
import { useTeam, useTranslatable } from '@/composables'
import { between, required } from '@core/utils/validation'
import { } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { v4 as uuidv4 } from 'uuid'

export default {
  components: { TranslationLocales, TranslatableInput, TranslatableEditor },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    const field = (name, initValue) => ({ [name]: props.resource ? props.resource[name] : initValue })

    const initialForm = {
      ...field('team_id', null),
      ...field('slug', ''),
      ...field('title', useTranslatable('title')),
      ...field('reg_brand', useTranslatable('reg_brand')),
      ...field('reg_intro', useTranslatable('reg_intro')),
      ...field('content', useTranslatable('content')),
      ...field('started_at', null),
      ...field('expires_at', null),
      ...field('coupon_code', ''),
      ...field('credits_reference', 0),
      ...field('cta_url', 'https://app.mixcarehealth.com/medical/pass'),
      ...field('active', true),
      ...field('post_uuid', uuidv4()),

      // ...field('active', false),
    }

    if (initialForm.started_at) {
      initialForm.started_at = now(initialForm.started_at).format('YYYY-MM-DD')
    }
    if (initialForm.expires_at) {
      initialForm.expires_at = now(initialForm.expires_at).format('YYYY-MM-DD')
    }

    const form = ref({ ...initialForm })
    const formElem = ref(null)

    const validate = () => {
      if (!formElem.value.validate()) return
      const data = { ...form.value }
      data.started_at = now(data.started_at).toString()
      data.expires_at = now(data.expires_at).toString()
      emit('submit', data)
    }

    const userOptions = ref([])
    const { teamOptions } = useTeam()

    return {
      form,
      formElem,
      validate,
      required,
      between,

      userOptions,
      teamOptions,
    }
  },
}
</script>
