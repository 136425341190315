<template>
  <v-navigation-drawer
    :value="active"
    permanent
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 580 : '100%'"
    app

    @input="(val) => $emit('aside', val)"
  >
    <v-container>
      <a @click="$emit('aside', false)">Back</a>
    </v-container>

    <campaign-form
      :resource="resource"
      :loading="loading"
      :errors="errors"
      @submit="handleSubmit"
    />
  </v-navigation-drawer>
</template>

<script>
import { useExtractErrors } from '@/composables'
import { storeCampaign, updateCampaign } from '@api/distributor/campaign'
import { mdiClose } from '@mdi/js'
import { ref } from '@vue/composition-api'
import CampaignForm from './CampaignForm.vue'

export default {
  components: { CampaignForm },
  model: {
    prop: 'active',
    event: 'aside',
  },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    resource: {
      type: [Boolean, Object],
      default: false,
    },
  },
  setup(props, { emit }) {
    const loading = ref(false)
    const errors = ref([])

    const handleSubmit = async form => {
      loading.value = true
      const request = props.resource ? updateCampaign(props.resource.id, { ...form }) : storeCampaign({ ...form })
      await request
        .then(async res => {
          // await store.dispatch('distributor/fetchAllTeams')
          emit('changed', res.data.data)
        })
        .catch(err => {
          errors.value = useExtractErrors(err)
        })
      loading.value = false
    }

    return {
      handleSubmit,
      loading,
      errors,

      icons: {
        mdiClose,
      },
    }
  },
}
</script>
