var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "formElem"
  }, [_c('translation-locales'), _c('v-card', [_c('v-card-text', [_c('v-select', {
    attrs: {
      "label": "Team",
      "item-text": "title",
      "item-value": "value",
      "outlined": "",
      "items": _vm.teamOptions,
      "disabled": !!_vm.resource
    },
    model: {
      value: _vm.form.team_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "team_id", $$v);
      },
      expression: "form.team_id"
    }
  }), _c('v-text-field', {
    staticClass: "mt-5",
    attrs: {
      "label": "Slug",
      "hint": "https://app.mixcarehealth.com/campaign/{slug}",
      "outlined": ""
    },
    model: {
      value: _vm.form.slug,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "slug", $$v);
      },
      expression: "form.slug"
    }
  }), _c('v-text-field', {
    staticClass: "mt-5",
    attrs: {
      "label": "Coupon Code (optional)",
      "hint": "leave blank if no automatic code application",
      "outlined": ""
    },
    model: {
      value: _vm.form.coupon_code,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "coupon_code", $$v);
      },
      expression: "form.coupon_code"
    }
  }), _c('v-text-field', {
    staticClass: "mt-5",
    attrs: {
      "label": "Credits earned upon registration (display only)",
      "hint": "leave 0 if no credits upon registraion",
      "outlined": "",
      "rules": _vm.between(_vm.form.credits_reference, 0, 9999999)
    },
    model: {
      value: _vm.form.credits_reference,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "credits_reference", $$v);
      },
      expression: "form.credits_reference"
    }
  }), _c('v-text-field', {
    staticClass: "mt-5",
    attrs: {
      "label": "CTA target url",
      "hint": "This is the send-user-to page when he clicks CTA from a campaign. Will go to register and verification page first depending on requirements of the target page.",
      "placeholder": "https://app.mixcarehealth.com/medical-pass/subscribe",
      "outlined": ""
    },
    model: {
      value: _vm.form.cta_url,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "cta_url", $$v);
      },
      expression: "form.cta_url"
    }
  }), _c('translatable-input', {
    staticClass: "mt-5",
    attrs: {
      "label": "Title",
      "outlined": ""
    },
    model: {
      value: _vm.form.title,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "title", $$v);
      },
      expression: "form.title"
    }
  }), _c('translatable-editor', {
    staticClass: "mt-5",
    attrs: {
      "label": "Brand Section in Registration above Sign up",
      "outlined": "",
      "eidtor-options": {
        uploadUrl: 'campaign',
        postUuid: _vm.form.post_uuid
      }
    },
    model: {
      value: _vm.form.reg_brand,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "reg_brand", $$v);
      },
      expression: "form.reg_brand"
    }
  }), _c('translatable-editor', {
    staticClass: "mt-5",
    attrs: {
      "label": "Intro in Registration Under Sign up",
      "outlined": "",
      "eidtor-options": {
        uploadUrl: 'campaign',
        postUuid: _vm.form.post_uuid
      }
    },
    model: {
      value: _vm.form.reg_intro,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "reg_intro", $$v);
      },
      expression: "form.reg_intro"
    }
  }), _c('translatable-editor', {
    staticClass: "mt-5",
    attrs: {
      "label": "Content",
      "outlined": "",
      "eidtor-options": {
        uploadUrl: 'campaign',
        postUuid: _vm.form.post_uuid
      }
    },
    model: {
      value: _vm.form.content,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "content", $$v);
      },
      expression: "form.content"
    }
  }), _c('h3', {
    staticClass: "mt-2"
  }, [_vm._v(" From ")]), _c('v-date-picker', {
    model: {
      value: _vm.form.started_at,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "started_at", $$v);
      },
      expression: "form.started_at"
    }
  }), _c('v-divider', {
    staticClass: "mt-2"
  }), _c('h3', {
    staticClass: "mt-2"
  }, [_vm._v(" To ")]), _c('v-date-picker', {
    model: {
      value: _vm.form.expires_at,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "expires_at", $$v);
      },
      expression: "form.expires_at"
    }
  }), _c('v-switch', {
    attrs: {
      "color": "primary",
      "label": _vm.form.active ? 'Active' : 'Inactive'
    },
    model: {
      value: _vm.form.active,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "active", $$v);
      },
      expression: "form.active"
    }
  })], 1), _c('v-card-actions', {
    staticClass: "justify-center"
  }, [_c('v-btn', {
    attrs: {
      "color": "primary",
      "loading": _vm.loading
    },
    on: {
      "click": _vm.validate
    }
  }, [_vm._v(" Submit ")])], 1), _vm._l(_vm.errors, function (msg) {
    return _c('v-alert', {
      key: msg,
      attrs: {
        "color": "warning"
      }
    }, [_vm._v(" " + _vm._s(msg) + " ")]);
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }