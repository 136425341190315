<template>
  <div>
    <v-row class="my-0 d-flex justify-end">
      <v-col
        cols="12"
        sm="2"
        style="margin-right:auto"
        align="right"
      >
        <v-btn
          color="primary"
          block
          class=""
          :loading="loading"
          :disabled="loading"
          @click="loadCampaigns"
        >
          <v-icon class="prefix-icon">
            {{ icons.mdiRefresh }}
          </v-icon>
          <span>
            {{ $t('common.list.refresh') }}
          </span>
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        sm="2"
        align="right"
      >
        <v-btn
          depressed
          block
          color="success"
          @click="setAside(null)"
        >
          <v-icon class="prefix-icon">
            {{ icons.mdiPlus }}
          </v-icon>
          {{ $t('common.list.create') }}
        </v-btn>
      </v-col>
    </v-row>

    <!-- table -->
    <v-data-table
      v-model="tableSelectedData"
      :headers="tableColumns"
      :items="tableList"
      :options.sync="options"
      :server-items-length="tableTotal"
      :loading="loading"
      class="text-no-wrap"
      :header-props="headerprops"
    >
      <!-- ID -->
      <template #[`item.id`]="{ item }">
        #{{ item.id }}
      </template>
      <!-- title -->
      <template #[`item.title`]="{item}">
        {{ t(item.title) }}
      </template>

      <!-- team -->
      <template #[`item.team_id`]="{item}">
        {{ item.team.nicename }}
        ({{ item.team.distributor.slug }})
      </template>

      <!-- started_at -->
      <template #[`item.started_at`]="{item}">
        <span class="text-no-wrap">{{ formatDate(item.started_at) }}</span>
      </template>

      <!-- expires_at -->
      <template #[`item.expires_at`]="{item}">
        <span class="text-no-wrap">{{ formatDate(item.expires_at) }}</span>
      </template>

      <!-- actions -->
      <template #[`item.actions`]="{item}">
        <div class="d-flex align-center justify-center">
          <!-- dropdown -->
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon size="18">
                  {{ icons.mdiDotsVertical }}
                </v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                link
                @click="setAside(item)"
              >
                <v-list-item-title>
                  <v-icon
                    size="18"
                    class="me-2"
                  >
                    {{ icons.mdiPencilOutline }}
                  </v-icon>
                  <span>Edit</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                link
                @click="deleteCampaign(item)"
              >
                <v-list-item-title>
                  <v-icon
                    size="18"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Delete</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-data-table>
    </v-card>

    <campaign-aside
      v-if="aside"
      v-model="aside"
      :resource="resource"
      @changed="loadCampaigns(); aside = false"
    ></campaign-aside>
  </div>
</template>

<script>
import {
mdiDeleteOutline, mdiDotsVertical,
mdiMenuDown,
mdiMenuUp,
mdiPencilOutline,
mdiPlus,
mdiRefresh,
} from '@mdi/js'

import { t } from '@/plugins/i18n'
import { formatDate } from '@core/utils/filter'
import { ref } from '@vue/composition-api'

import CampaignAside from '../components/campaign/CampaignAside.vue'
import useCampaignList from '../composables/useCampaignList'

export default {
  components: { CampaignAside },

  setup() {
    const {
      tableList, tableColumns, tableTotal, tableSelectedData, searchQuery, emailQuery, options, loadCampaigns, deleteCampaign, loading,
    } = useCampaignList()

    const actions = ['Delete', 'Edit', 'Send']

    const selectedAction = ref('')

    const aside = ref(false)
    const resource = ref(null)
    const setAside = item => {
      aside.value = true
      resource.value = item
    }

    const singleSelect = ref(false)
    const headerprops = ref({
      'sort-icon': mdiMenuDown,
    })
    const showFilter = ref(true)

    return {
      tableColumns,
      tableList,
      tableSelectedData,
      tableTotal,

      searchQuery,
      emailQuery,

      loadCampaigns,
      deleteCampaign,
      setAside,
      aside,
      resource,

      options,
      loading,
      actions,
      selectedAction,

      t,
      formatDate,

      icons: {
        mdiDotsVertical,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiMenuUp,
        mdiMenuDown,
        mdiRefresh,
        mdiPlus,
      },
      singleSelect,
      headerprops,
      showFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
