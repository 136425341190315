import axios from '@axios'

const fetchCampaigns = queryParams => axios.get('/admin/campaigns', { params: queryParams })

const storeCampaign = data => axios.post('/admin/campaigns', data)

const updateCampaign = (id, data) => axios.put(`/admin/campaigns/${id}`, data)

const destroyCampaign = id => axios.delete(`/admin/campaigns/${id}`)

export {
    fetchCampaigns,
    storeCampaign,
    updateCampaign,
    destroyCampaign,
}

